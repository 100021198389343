@import '../../common/variables.scss';
@import '../../common/loader.scss';

.label {
	font-weight: bold;
	margin-bottom: 5px;
}

.labelRequired {
	font-weight: bold;
	margin-bottom: 5px;
	color: $primaryColor;
}

.inputs {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.input {
	width: 250px;
	margin-bottom: 15px;
	height: 20px;
	padding-left: 10px;
	border: 2px lightgrey solid;
	font-size: 1rem;
}

.selectInput {
	width: 260px;
	font-size: 1rem;
	height: 26px;
	border: 2px lightgrey solid;
	margin-bottom: 15px;
}

.messageInput {
	width: 350px;
	height: 150px;
	resize: none;
	border: 2px lightgrey solid;

	@include scrollbar();
}

.errorInput {
	color: red;
	border: 2px red solid;
	&:focus {
		outline: none;
	}
}

.buttonInput {
	margin: 15px;
	width: 100px;
	line-height: 43px;
	height: 43px;
	font-size: 1rem;
	background: $primaryColor;
	color: white;
	border: none;
	border-radius: 30px;
	font-weight: bold;

	&:hover {
		background: $secondaryColor;
		color: $primaryColor;
		cursor: pointer;
	}

	&:focus {
		outline: none;
	}

	&:disabled {
		background: #cccccc;
		color: #aaaaaa;
	}
}

@include media('>desktop') {
	.messageInput {
		width: 500px;
		height: 300px;
	}

	.allInputs {
		display: flex;
		justify-content: center;
	}

	.inputs {
		margin: 0 25px;
	}
}
