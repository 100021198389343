@import '../../common/variables.scss';

.row {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: baseline;
	margin-bottom: 15px;
}

.detailLabel {
	margin: 0;
}

.detailValue {
	margin: 0;
	margin-left: auto;
}

.overRow {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.overLabel {
	margin: 0;
	margin-bottom: 5px;
}

.overValue {
	margin: 0;
}

.foto {
	width: 300px;
	margin-top: 30px;
}

@include media('>tablet') {
	.row {
		width: 500px;
	}

	.detailValue {
		font-size: 1.2rem;
	}

	.overRow {
		max-width: 750px;
	}

	.overValue {
		font-size: 1.2rem;
	}
}
