@import '../../common/variables.scss';

.mainHeader {
	margin-bottom: 15px;
}

.bewonersContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.bewonerCard {
	display: grid;
	justify-content: center;
	text-align: center;
	margin: 10px;
	width: 300px;
	height: 300px;
}

.name {
	margin: 3px 0;
	color: $primaryColor;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}

.foto {
	width: 250px;
	border-radius: 50%;
	border: 4px transparent solid;

	&:hover {
		cursor: pointer;
		border: 4px $primaryColor solid;
	}
}
