@import './_include-media.scss';

$primaryColor: #fe7907;
$secondaryColor: #ffffff;
$background: url('common/pageglare.png') no-repeat,
	-webkit-linear-gradient(
			top,
			rgba(212, 99, 0, 0.15) 0,
			rgba(242, 112, 0, 0.13) 111px,
			rgba(255, 169, 94, 0.31) 500px
		) no-repeat,
	url('common/page.png') bottom left repeat-x,
	-webkit-linear-gradient(top, #eb6d00 0, #ff7600 111px, #ff9d47 500px) no-repeat;
$backgroundContent: rgba(255, 255, 255, 0.7);
$black: #3c3c3c;

$navbarHeight: 83px;
$navitemHeight: 43px;
$hamburgerHeight: 33px;
$footerHeight: 60px;
// 2x padding / margin
$totalFooterHeight: calc(#{$footerHeight} + 30px + 15px);

$phone: 40rem !default;
$tablet: 48rem !default;
$desktop: 64rem !default;

@mixin scrollbar() {
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	&::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 30px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

.mainText {
	text-align: center;
}

.mainHeader {
	color: $primaryColor;
	margin-bottom: 0;
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	// 100% - footerheight
	min-height: calc(100% - #{$totalFooterHeight});
}

.link {
	color: black;
	text-decoration: none;
	font-weight: bold;

	&:hover {
		text-decoration: underline;
	}
}

@include media('>tablet') {
	.container {
		padding: 20px;
		// 100% - footerheight - 2x padding
		min-height: calc(100% - #{$totalFooterHeight} - 40px);
	}

	.mainText {
		font-size: 1.5rem;
	}
}

@include media('>desktop') {
	.mainHeader {
		font-size: 2.5rem;
	}

	.mainText {
		max-width: 1000px;
		font-size: 1.5rem;
	}
}

$breakpoints: (
	phone: $phone,
	tablet: $tablet,
	desktop: $desktop,
) !default;
