@import '../../common/variables.scss';
@import '../../common/loader.scss';

.photoContainer {
	width: 100%;
	height: calc(100% - #{$footerHeight} - 50px);
	display: flex;
	flex-wrap: wrap;
	padding: 4px;
	justify-content: center;
}

.photoColumn {
	flex: 100%;
	max-width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.photo {
	width: 400px;
	height: inherit;
	margin: 10px;
	vertical-align: middle;
}

@include media('>tablet') {
	.photoColumn {
		flex: 33%;
		max-width: 33%;
	}

	.photo {
		width: 75%;
	}
}
