@import '../common/variables.scss';

.popup {
	width: 100vw;
	height: 100vh;
	background: rgba(60, 60, 60, 0.6);
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.popupCard {
	background: #eeeeee;
	display: grid;
	grid-template-areas:
		'header header'
		'image text';
	width: 350px;
	height: 150px;
	padding: 10px;
	justify-content: center;
	align-items: center;

	h1 {
		grid-area: header;
		font-size: 1.5rem;
		margin: auto;
	}

	img {
		grid-area: image;
		width: 100px;
	}

	p {
		grid-area: text;
	}

	svg {
		position: absolute;
		margin-left: 340px;

		&:hover {
			cursor: pointer;
		}
	}
}

.navbar {
	color: $secondaryColor;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: fixed;
	top: 0;
	height: $navbarHeight;
}

.mobileOverlay {
	background: $black;
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	opacity: 0.8;
	transition: 1s;
}

.navItems {
	display: flex;
	flex-direction: column;
	position: absolute;
	transition: 1s;
	right: 0;
	top: 0;
	width: 60%;
	height: 100vh;
	background-color: $primaryColor;
	padding: 10px;
	align-items: center;
}

.navlink {
	width: 50%;
	text-decoration: none;
	color: $secondaryColor;
	border-radius: 30px;
	border: 2px $primaryColor solid;
	padding: 5px 15px;
	font-weight: bold;
	line-height: $navitemHeight;
	text-align: center;
	font-size: 18px;
	margin-top: 10px;
	min-width: 70px;
	cursor: pointer;

	&:hover {
		border: 2px $secondaryColor solid;
		background-color: $secondaryColor;
		color: $primaryColor;
	}
}

.home {
	display: none;
}

.hamburger {
	font-size: $hamburgerHeight;
	margin-right: 25px;
	cursor: pointer;
}

.activeNavlink {
	border: 2px $secondaryColor solid;
}

.content {
	overflow-x: hidden;
	@include scrollbar();
	height: calc(100vh - #{$navbarHeight});
	background: $backgroundContent;
	width: 90%;
	margin: auto;
	padding: 0 10px;
	margin-top: $navbarHeight;
}

.footer {
	height: $footerHeight;
	background: #dddddd;
	margin-top: 15px;
	padding: 15px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.footerLinks {
	display: flex;
	justify-content: space-evenly;
	width: 60%;

	a {
		color: black;
		font-weight: 600;

		&:hover {
			color: $primaryColor;
		}
	}
}

.inactive {
	right: -100%;
	position: absolute;
	opacity: 0;
	z-index: -1;
}

@include media('>tablet') {
	.popupCard {
		width: 500px;
		height: 200px;

		h1 {
			font-size: 2rem;
		}

		img {
			width: 125px;
		}

		svg {
			margin-left: 490px;
		}
	}

	.navlink {
		width: 50%;
		padding: 10px 15px;
		font-size: 2rem;
		border: 4px transparent solid;
		margin: 10px;

		&:hover {
			border: 4px $secondaryColor solid;
		}
	}

	.activeNavlink {
		border: 4px $secondaryColor solid;
	}

	.navItems {
		padding: 25px;
	}

	.hamburger {
		font-size: 50px;
	}
}

@include media('>desktop') {
	.popupCard {
		p {
			font-size: 1.5rem;
		}
	}

	.navbar {
		justify-content: flex-start;
		margin: 0 5%;
		width: 90%;
	}

	.navItems {
		display: flex;
		flex-direction: row;
		width: 100%;
		margin-right: 10px;
		opacity: 1;
		background: transparent;
		height: inherit;
		padding: 0;
		right: 0;
	}

	.navlink {
		padding: 5px 10px;
		width: auto;
		border-radius: 10px;
		font-size: 1.5rem;
		border: 4px transparent solid;
		margin: 0 5px;

		&:hover {
			border: 4px $secondaryColor solid;
		}
	}

	.activeNavlink {
		border: 4px $secondaryColor solid;
	}

	.hamburger {
		display: none;
	}

	.home {
		display: block;
	}
}
