@import '../../common/variables.scss';

.header {
	text-align: center;
	margin: 0;
}

@include media('>tablet') {
	.header {
		font-size: 2rem;
	}
}
