@import '../../common/variables.scss';

.logo {
	width: 250px;
}

.seperator {
	width: 300px;
	border: none;
	height: 1px;
	background: black;
}

.calendar {
	height: 300px;
	width: 80%;
}

.kerstdinerImage {
	margin-top: 20px;
	width: 90%;
}

@include media('>tablet') {
	.calendar {
		width: 500px;
	}

	.kerstdinerImage {
		width: 600px;
	}
}

@include media('>desktop') {
	.logo {
		width: 500px;
	}

	.seperator {
		width: 40%;
		margin-top: 25px;
	}

	.kerstdinerImage {
		width: 900px;
	}
}
